module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Outside Context Development","short_name":"Outside Context Development","description":"A dedicated software development team working with you to meet business needs","lang":"en","start_url":"/","icon":"src/assets/favicon.png","background_color":"#002b36","theme_color":"#2ab6b6","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6de1e8ecdf9250f5c5cef879b9462e5f"},
    }]
